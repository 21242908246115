<template>
  <v-app>
    <v-main>
      <v-overlay :opacity="opacity" :value="overlayToggle">
        <h1>Alles wie immer</h1>
        <div class="loginbox">
          <v-text-field
          type="password"
            v-model="password"
            label="Das Passwort bitte"
            placeholder="Wie du weisst das nicht?!"
            outlined
          ></v-text-field>
          <v-btn @click="validate">Login</v-btn>
          <div v-if="beleidigung">{{ currentBeleidigung }}</div>
        </div>
      </v-overlay>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-img :src="currImage"></v-img>
        </v-card>
      </v-dialog>
      <v-container>
        <v-row justify="center">
          <v-col xs="12" class="br">
            <h4 style="text-align: center" class="text-decoration-underline">
              Strafordnung zum traditionellen Weihnachtsmarsch
              <br />(Abgekürzt: StrafOWMarsch)
            </h4>
            <br />
            <h4>§ 1 Kompetenzen</h4>
            <p class="body-2">
              <sup>1</sup>Der Vorstand ist das Alpha und das Omega, der Anfang
              und das Ende, Tag und Nacht.
              <br />
              <sup>2</sup>In seiner unfehlbaren Weisheiterkennt und spricht er
              das Recht.
            </p>
            <h4>§ 2 Definitionen</h4>
            <p class="body-2">
              <sup>1</sup>Seit Anbeginn der Zeit, es ist, wie es immer ist und
              es bleib, wie es immer war. <sup>2</sup>Hier wird NICHTS
              verändert. <sup>3</sup>Treffpunkt ist der Treffpunkt.
              <sup>4</sup>Uhrzeit und Ort sind bekannt.
            </p>
            <h4>§ 3 Wandervoraussetzungen</h4>
            <p class="body-2">
              <sup>1</sup>Die Teilnahme ist dem Grunde nach nur straffrei
              gestattet, sofern die Bescheinigung der WEUWEF unaufgefordert zu
              Beginn der Wanderung vorgelegt wird. <sup>2</sup>Auf die
              erhaltenen und aufzubewahrenden Dokumente aus dem Jahr 2018 wird
              verwiesen.
            </p>
            <h4>§ 4 Erkennungszeichenpflicht</h4>
            <p class="body-2">
              <sup>1</sup>Die Erkennungszeichen der heiligen Wanderung sind
              jederzeit sichtbar zu tragen. <sup>2</sup>Zu diesen gehören
              Becher, Button, Bild, Schal und Lebkuchenherz.
            </p>
            <h4>§ 5 Strafbarkeit</h4>
            <p class="body-2">
              Strafbar können sich alle Teilnehmer im trinkfähigen Alter machen
            </p>
            <h4>§ 6 Verbrechen</h4>
            <p class="body-2">
              Verbrechen an dem Marsch und der Menschheit insgesamt sind die
              nachfolgenden Taten:
              <br />
              <br />1. Verunglimpfungen, Beleidigungen und Verleumdungen der
              Vorstandsmitglieder <br />2. Verweigerung der Zugleistung am
              Getränkeschlepper, <br />3. Unfähige Führung des
              Getränkeschleppers, insbesondere die geringste Herbeiführung einer
              Kippbewegung des Fahrzeugs, <br />4. Ableistung einer verkürzten
              Wanderstrecke, <br />5. Missachtung der herkömmlichen Antwort-und
              Erklärungspflichten, <br />6. Missachtung der Haltepunkte
              <br />7. Wegbleiben oder Störung des Vollzugs des geregelten
              Betriebsablaufs, insbesondere Vereitelung der fristgerechten
              Abreise, <br />8. Vorzeitige Mandatsabgabe, vgl. Liz Truss,
              <br />9. Missachtung der Wandervoraussetzungen und
              Erkennungszeichenpflicht.
            </p>
            <h4>§ 7 Strafe</h4>
            <p class="body-2">
              <sup>1</sup>Strafe muss sein. <sup>2</sup>Die in § 6 bezeichneten
              Verbrechen werden durch den Vorstand in Prozessen, frei von jeder
              Gerechtigkeit, abgeurteilt. <sup>3</sup>Die folgenden und nicht
              abschließend aufgezählten Strafen werden dabei in jedem Fall
              ausgesprochen:
              <br />
              <br />1. Zugverpflichtungen, am Glas wie am Wagen, <br />2. 
              Aufführung peinlicher gesanglicher und/oder tänzerischer
              „Leistungen“, <br />3. Putzverpflichtungen, <br />4. Pizzabacken
              mit Strambo bis zum bitteren Ende, Verfeuerung aller Pizzen,
              <br />5. Reinigung des Backrohrs, <br />6. Schelle (aber nur für
              Michael).
            </p>

            <h4>§ 8 Inkrafttreten</h4>
            <p class="body-2">
              <sup>1</sup>Diese Ordnung gilt seit 1200 vor der ersten amtlich
              durchgeführten und formell bestätigten Abwanderung des heiligen
              Marsches. <sup>2</sup>Der Vorstand ist jederzeit berechtigt
              Änderungen vorzunehmen.
            </p>
            <h4>§ 9 Mitgelten Gesetze</h4>
            <p class="body-2">
              Auf §§ 21 Abs. 1, 5 Abs. 1, 3 Wanderpflichtsgesetz, § 2
              AlkoholAm22.12.TrinkGesetzwird verwiesen.
            </p>
            <h4>§ 10 Abschlussformel</h4>
            <p class="body-2">Der Bass muss ficken, ihr Ficker.</p>
          </v-col>
        </v-row>
        <v-row class="bb">
          <v-col xs="12">
            <div class="swiperContainer">
              <div ref="swiper" class="swiper">
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                  <!-- Slides -->
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Arne.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Ervin.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Gruppe.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Hossi.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Jacob.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Knut.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Lennart.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Martin.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Marvin.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Micha.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Micha2.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Silas.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Sören.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Stefan.jpg" />
                    </div>
                  </div>
                  <div @click="large" class="swiper-slide">
                    <div class="swiper-zoom-container">
                      <img src="../public/Tim.jpg" />
                    </div>
                  </div>
                </div>
                <!-- If we need pagination -->

                <!-- If we need navigation buttons -->
                <div class="swiper-button-prev swiper-button-black"></div>
                <div class="swiper-button-next swiper-button-black"></div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Swiper, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
export default {
  name: "App",
  mounted() {
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation],

      // Optional parameters
      slidesPerView: 1,

      zoom: true,
      spaceBetween: 10,
      breakpoints: {
        // when window width is >= 320px
        480: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },

      direction: "horizontal",
      loop: true,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },

  components: {},
  setup() {
    return {};
  },
  data() {
    return {
      beleidigungen: [
        
        "Was hat deine Mutter eigentlich falsch gemacht?",
        "Da muss er aber noch größer werden wa?",
        "Zu wenig Fruchtzwerge in den Schritt geschmiert?",
        "Blaue Pille vergessen?",
        "Geistig nicht so potent?",
        "Daneben?! Und das beim Einschütten? So doof kann man doch nicht sein!"

      ],
      currentBeleidigung: "Du tippst wie eine Kuh",
      beleidigung: false,
      password: null,
      currImage: "",
      dialog: false,
      opacity: 1,
      overlayToggle: true,
    };
  },
  methods: {
    validate: function () {
     
      if (this.password == "Ficker") {
        
        this.overlayToggle = false;
      } else {
        this.beleidigung = true;
        this.currentBeleidigung =
          this.beleidigungen[
            Math.floor(Math.random() * this.beleidigungen.length)
          ];
      }
    },
    large: function (event) {
      this.currImage = event.target.src;
      this.dialog = true;
      console.log(event);
    },
  },
};
</script>
<style>
* {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  text-align: left;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: hotpink;
}
.loginbox {
  width: 80vw;
}
.swiperContainer {
  position: relative;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pdfContainer {
  height: 50vh;
}

.childSize {
  height: 100%;
}
</style>
